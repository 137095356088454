<template lang="pug">
modal(size='sm' @close='$emit("close")')
  div(class='project-form-outer')
    overlay(:visible='loadingForm || projectsLoading' class='!rounded-xl')
    div(class='absolute right-6 top-4 z-50')
      btn(plain icon='x' data-target='form-header-close' @click='close')
    drilldown(v-model='activeItem')
      drilldown-item#a
        template(#default)
          div(class='project-form')
            div(class='project-form__header')
              h4(class='mt-3 font-thin' v-html='$tc(" | <b>1 Produkt </b> hinzufügen | <b>{0} Produkte</b> hinzufügen", hasMultipleProducts ? productIds.length : 1)')
            template(v-if='projects.length > 0')
              transition-group(name='fade')
                btn-select(v-for='project in projects' :key='project.id' arrow class='!py-3' @click.prevent='setProject(project)')
                  template(#image)
                    project-image(:project='project' :size='45')
                  span(class='font-family-lato font-bold') {{ project.title }}
              div(v-if='projects.length > 0' class='project-form__footer')
                btn(secondary icon='plus' data-target='new-project' @click='$modals.open("form-project-create")') {{ $t('New Project') }}
            div(v-else class='project-form__emptyState')
              empty-state(:centered='true' :message='$t("No Projects yet")')
                template(#buttons)
                  btn(secondary icon='plus' data-target='new-project' @click='$modals.open("form-project-create")') {{ $t('New Project') }}

      drilldown-item#b
        template(#default)
          div(v-if='currentProject' class='project-form project-form--nested')
            div(class='project-form__header')
              btn(plain icon='arrow-left' :rounded='true' data-test-btn='SelectAllProjects' :data-tooltip='$t("All Projects")' class='project-form__buttonArrow' @click='currentProjectId = ""')
              div(class='project-form__headerInner')
                div
                  project-image(:project='currentProject' :size='55')
                h5(class='mb-0')
                  router-link(:to='{ name: "/projects/[id]/", params: { id: currentProject.id } }' class='link-dark') {{ currentProject.title }}
            tabs#AddProjectsTabs
              template(#tabs)
                tab-button#tags(:active='selectedTab === "tags"' @click='selectedTab = "tags"') {{ $t('Tags') }}
                tab-button#inquiries(:active='selectedTab === "inquiries"' @click='selectedTab = "inquiries"') {{ $t('Inquiries') }}
              keep-alive
                div(v-if='selectedTab === "tags"' class='pt-4')
                  template(v-if='tags.length > 0')
                    btn-select(:hoverText='$t("add")' data-test-btn='AddProductsToProject' class='!py-4' @click.prevent='submitToProject()')
                      span(class='font-family-lato font-bold') {{ $t('Complete project') }}
                    transition-group(name='fade')
                      btn-select(v-for='tag in tags' :key='tag.name' data-test-btn='AddProductsToTag' :hoverText='$t("add")' @click.prevent='submitToProjectTag(tag.name)')
                        span(class='font-family-lato font-bold') {{ tag.name }}
                    div(class='project-form__footer')
                      btn(secondary icon='plus' @click.prevent='$utils.routeQueryAdd({ "form-project-tag-create": currentProjectId })') {{ $t('New Tag') }}
                  template(v-else)
                    div(class='project-form__footer mt-0 !pt-0')
                      notification(class='mb-2') {{ $t('There are no tags in your project yet. Tags help you organize your projects.') }}
                      div(class='flex flex-wrap items-center gap-3')
                        btn(secondary data-test-btn='AddProductsToProject' @click.prevent='submitToProject()') {{ $t('Add to project') }}
                        span {{ $t('or') }}
                        btn(secondary icon='plus' @click.prevent='$utils.routeQueryAdd({ "form-project-tag-create": currentProjectId })') {{ $t('New Tag') }}
              keep-alive
                div(v-if='selectedTab === "inquiries"' class='pt-4')
                  template(v-if='currentInquiries')
                    btn-select(v-for='inquiry in currentInquiries' :key='inquiry.id' :hoverText='$t("add")' :disabled='inquiry.status !== "drafted"' @click.prevent='submitToInquiry(inquiry.id)')
                      b {{ inquiry.inqTitle || inquiry.id }}
                      inquiry-status-pill(class='ml-2' :status='inquiry.status')
                  div(v-if='currentInquiries.length === 0' class='project-form__emptyState')
                    notification {{ $t('No inquiries in draft mode yet') }}
                  div(class='project-form__footer')
                    btn(v-if='currentProject && currentInquiries.length > 0' link :to='{ name: "/projects/[id]/inquiries", params: { id: currentProject.id } }' class='flex')
                      span {{ $t('All Inquiries') }}
                      icon(name='arrow-right' class='relative')
</template>

<script lang="ts">
import BtnSelect from '@/components/BtnSelect.vue'
import { type IFormResponseInfo } from '@/components/GenericForm/types'
import { useListObject } from '@/components/List'
import { useOnSuccess, useThorForm } from '@/composables/'
import { useInquiriesStore } from '@/pages/inquiries/store'
import { useProjectsStore } from '@/pages/projects/store'
import { type IProjectData } from '@/pages/projects/types'
import { type IPconDTO } from '@/types'
import { useSessionStorage } from '@vueuse/core'
import { isArray, objectify } from 'radash'
import { computed, defineComponent, onMounted, type Ref, ref, type SetupContext, watch } from 'vue'
import { type PropType } from 'vue'

const FormProjectAddProject = defineComponent({
  components: { BtnSelect },
  props: {
    productIds: { type: Array as PropType<string[]>, required: true },
    projectId: { type: String, required: true },
    sourceProjectId: String,
    pcon: Object as PropType<IPconDTO>,
  },
  setup(props, context: SetupContext) {
    // get current project id from storage
    const currentProjectId = useSessionStorage('FormProjectAddProject.currentProjectId', '')
    if (props.projectId) {
      currentProjectId.value = props.projectId
    }
    const { items, loading, replaceItem, addItem } = useListObject<IProjectData>({
      id: 'AddProductToProject',
      endpoint: '/ProjectSearch',
      pageSizes: [40],
      context,
      additionalProperties: {
        isActive: ['true'],
        sort: 'created.at_desc',
      },
      useRouter: false,
    })
    useOnSuccess<IProjectData>(['Projects/*/Create'], (r: IFormResponseInfo<IProjectData>) => addItem(r.data!))
    // if currentProject id from store is not in store (deleted) -> reset
    const projectsAsMap = computed(() => objectify(items.value, (item) => item.id))
    watch(
      () => projectsAsMap.value,
      () => {
        if (!projectsAsMap.value[currentProjectId.value]) {
          currentProjectId.value = ''
        }
      },
    )

    const selectedProjectId: Ref<string> = ref(currentProjectId.value || '')
    const currentProject = computed(() =>
      currentProjectId.value ? projectsAsMap.value[currentProjectId.value] : undefined,
    )
    const { post, loading: loadingForm } = useThorForm()

    const store = useProjectsStore()
    const inqStore = useInquiriesStore()
    const loadInquiries = () => inqStore.loadInquiriesForProject(currentProjectId.value)
    onMounted(() => currentProjectId.value && loadInquiries())

    const activeItem = computed(() => (currentProjectId.value === '' ? 'a' : 'b'))

    // update project when tag was added
    useOnSuccess(['AddTag'], (r: IFormResponseInfo<IProjectData>) => replaceItem(r.data))

    const submitAddInternalProduct = (action: string, tagName?: string, inquiryId?: string) =>
      post(
        !props.pcon
          ? `/_/AddProductToProject/${action}`
          : `/_/AddOrEditPconProduct/*/CreateForm/${action}?projectId=${selectedProjectId.value}`,
        {
          SelectedTag: tagName ? [tagName] : [],
          SelectedProject: selectedProjectId.value,
          SelectedProducts: props.productIds,
          SelectedInquiry: inquiryId || '',
          SourceProjectId: props.sourceProjectId || '',
        },
        action,
      ).subscribe(() => context.emit('close'))
    return {
      props,
      loadingForm,
      submitToProjectTag: (tagName: string) =>
        props.pcon
          ? store
              .addOrEditPconProduct(selectedProjectId.value, undefined, undefined, undefined, tagName, props.pcon!)
              .then(() => context.emit('close'))
          : submitAddInternalProduct('AddProductsToProject', tagName),

      submitToProject: () =>
        props.pcon
          ? store
              .addOrEditPconProduct(selectedProjectId.value, undefined, undefined, undefined, undefined, props.pcon)
              .then(() => context.emit('close'))
          : submitAddInternalProduct('AddProductsToProject'),

      submitToInquiry: (inquiryId: string) =>
        props.pcon
          ? inqStore
              .addOrEditPconProduct(selectedProjectId.value, inquiryId, undefined, undefined, props.pcon)
              .then(() => context.emit('close'))
          : submitAddInternalProduct('AddProductsToInquiry', undefined, inquiryId),

      selectedTab: ref('tags'),
      store: computed(() => store),
      projects: items,
      projectsLoading: loading,
      hasMultipleProducts: computed(() => isArray(props.productIds) && props.productIds.length > 1),
      activeItem,
      currentInquiries: computed(() => inqStore.activeInquiriesByProject[currentProjectId!.value] || []),
      tags: computed(() => (currentProject?.value as any)?.tagList?.tags || []),
      currentProjectId,
      currentProject,
      selectedProjectId,
      close: () => context.emit('close'),
      setProject: (project: IProjectData) => (
        (currentProjectId.value = project.id), (selectedProjectId.value = project.id), loadInquiries()
      ),
      onProjectClick: (projectId: string) => (selectedProjectId.value = projectId),
    }
  },
})

export default FormProjectAddProject
</script>
<style lang="stylus">
@import '../../../styles/variables.styl'

$button-select-bg-hover = rgba(#63A3C4, 10%)
$button-select-bg-active = rgba(#63A3C4, 20%)
:root
  --project-form-padding-left: 30px
  --project-form-padding-right: 30px
  --button-select-bg: transparent

.project-form-outer
  margin: -10px calc(var(--modal-padding-left) * -1) 0

.project-form
  --project-form-padding-left: var(--modal-padding-left)
  --project-form-padding-right: var(--modal-padding-right)
  &--nested
    --project-form-padding-left: 60px
  &__footer
    padding: 15px  var(--project-form-padding-right) 0px var(--project-form-padding-left)
    .btn[link]
      margin-left: -15px
  &__header
    border-radius: $border-radius $border-radius 0 0
    padding: 15px 70px 10px var(--project-form-padding-left)
    position: relative
    display: flex
    ~/--nested &
      padding-bottom: 10px
  &__headerInner
    align-items: center
    display: flex
    padding-top: 5px
    gap: 10px

  &__buttonArrow
    position: absolute!important
    left: 10px
    top: 20px
    font-size: 20px!important

  .tabs__content, &__emptyState
    margin: 0 20px 0
    padding-left: calc( var(--project-form-padding-left) - 20px)

  .tabs__button--isActive
    margin-bottom: 0

.project-form .btn-select
  padding: 15px calc(var(--project-form-padding-right) - 10px) 15px calc(var(--project-form-padding-left) - 10px)
  margin: 0 10px
  width: calc(100% - 20px)
</style>
